import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const Live = () => (
  <Layout className='h-100'>
    <h1 className='f4 f3-ns f2-m f1-l tracked ttu'>Live</h1>
    <p className='f3 lh-solid'>
      <audio
        controls='controls'
        autoplay=''
        src='http://live.primitivemachine.com:8080/stdin'
        type='audio/mp3'
      >
        <span>Your browser does not support the <code>audio</code> element.</span>
      </audio>
    </p>
    <Link
      className='f5 f4-ns f3-m f2-l tracked ttu mv4 black no-underline'
      to='#'
    >
      Home
    </Link>
  </Layout>
)

export default Live
