import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, Link, graphql } from 'gatsby'
import 'tachyons'
import './layout.css'
import SEO from './SEO'
import 'typeface-lilita-one'
import ExternalLink from './ExternalLink'

const Layout = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO />
        <div className={`center mw8 flex flex-column justify-center align-center${' ' + className}`}>
          <div className='flex justify-between f4 pa3 ph4-ns'>
            <ExternalLink
              className='f4 f3-ns f2-m f1-l tracked ttu'
              href='https://internationalwinners.bandcamp.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              releases
            </ExternalLink>
            {' '}
            <Link
              className='tr f4 f3-ns f2-m f1-l tracked ttu black no-underline'
              to='/info'
            >
              info
            </Link>
          </div>
          <div className='ma3 mh4-ns pa2 b--solid bw3 bw4-ns'>
            {children}
          </div>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
