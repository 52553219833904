import React from 'react'

const ExternalLink = ({ children, className, ...props }) =>
  <a
    {...props}
    className={`black no-underline${className ? ' ' + className : ''}`}
  >
    {children}
  </a>

export default ExternalLink
